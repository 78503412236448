<template>
  <div>
    <Header />

    <!--Container-->
    <div class="container w-full md:max-w-6xl mx-auto pt-20">
      <div
        class="w-full px-4 md:px-6 text-xl  leading-normal"
        style="font-family:Georgia,serif;"
      >
        <!--Title-->
        <div class="font-sans">
          <h1
            class="font-bold font-sans break-normal  pt-6 pb-2 text-5xl md:text-5xl text-center mb-8"
          >
            Terms of Service
          </h1>
          <p class="text-3xl md:text-3xl font-normal ">Terms and Conditions</p>
          <p class="text-sm">Last updated: January 27, 2021</p>
          <p class="text-sm">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <p class="text-3xl md:text-3xl font-normal  my-4">
            Interpretation and Definitions
          </p>
        </div>
        <div class="font-sans">
          <p class="text-xl md:text-xl font-normal  my-2">Interpretation</p>
          <p class="text-sm">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </div>
        <div class="font-sans">
          <p class="text-xl md:text-xl font-normal  my-2">Definitions</p>
          <p class="text-sm mb-4">
            For the purposes of these Terms and Conditions:
          </p>
          <ul style="list-style: disc" class="text-sm ml-8">
            <li>
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority.
            </li>
            <li>
              Account means a unique account created for You to access our
              Service or parts of our Service.
            </li>
            <li>Country refers to: Ohio, United States</li>
            <li>
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to Space Unlimited LLC, Vermilion, OH
              44089.
            </li>
            <li>
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </li>
            <li>
              Free Trial refers to a limited period of time that may be free
              when purchasing a Subscription.
            </li>
            <li>Goods refer to the items offered for sale on the Service.</li>
            <li>Orders mean a request by You to purchase Goods from Us.</li>
            <li>
              Promotions refer to contests, sweepstakes or other promotions
              offered through the Service.
            </li>
            <li>Service refers to the Website.</li>
            <li>
              Subscriptions refer to the services or access to the Service
              offered on a subscription basis by the Company to You.
            </li>
            <li>
              Terms and Conditions (also referred as "Terms") mean these Terms
              and Conditions that form the entire agreement between You and the
              Company regarding the use of the Service.
            </li>
            <li>
              Third-party Social Media Service means any services or content
              (including data, information, products or services) provided by a
              third-party that may be displayed, included or made available by
              the Service.
            </li>
            <li>
              Website refers to Spaceseats, accessible from
              http://www.spaceseats.io
            </li>
            <li>
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
          </ul>
        </div>
        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Acknowledgment
        </p>
        <div class="font-sans">
          <p class="text-sm">
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service.
            <br />
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service.
            <br />
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service. <br />
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use the Service.
            <br />
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
        </div>
        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Placing Orders for Goods
        </p>
        <div class="font-sans">
          <p class="text-sm">
            By placing an Order for Goods through the Service, You warrant that
            You are legally capable of entering into binding contracts.
          </p>
        </div>

        <div class="font-sans">
          <p class="text-xl md:text-xl font-normal  my-2">Your Information</p>
          <p class="text-sm">
            If You wish to place an Order for Goods available on the Service,
            You may be asked to supply certain information relevant to Your
            Order including, without limitation, Your name, Your email, Your
            phone number, Your credit card number, the expiration date of Your
            credit card, Your billing address, and Your shipping information.
          </p>
          <p class="text-sm">
            You represent and warrant that: (i) You have the legal right to use
            any credit or debit card(s) or other payment method(s) in connection
            with any Order; and that (ii) the information You supply to us is
            true, correct and complete.
          </p>
          <p class="text-sm">
            By submitting such information, You grant us the right to provide
            the information to payment processing third parties for purposes of
            facilitating the completion of Your Order.
          </p>
        </div>
        <div class="font-sans">
          <p class="text-xl md:text-xl font-normal  my-2">Order Cancellation</p>
          <p class="text-sm">
            We reserve the right to refuse or cancel Your Order at any time for
            certain reasons including but not limited to:
          </p>
          <ul style="list-style: disc" class="text-sm ml-8">
            <li>Goods availability</li>
            <li>Errors in the description or prices for Goods</li>
            <li>Errors in Your Order</li>
          </ul>
          <p class="text-sm">
            We reserve the right to refuse or cancel Your Order if fraud or an
            unauthorized or illegal transaction is suspected.
          </p>
        </div>
        <div class="font-sans">
          <p class="text-xl md:text-xl font-normal  my-2">
            Your Order Cancellation Rights
          </p>
          <p class="text-sm">
            Any Goods you purchase can only be returned in accordance with these
            Terms and Conditions and Our Returns Policy.
          </p>
          <p class="text-sm">
            Our Returns Policy forms a part of these Terms and Conditions.
            Please read our Returns Policy to learn more about your right to
            cancel Your Order.
          </p>
          <p class="text-sm">
            Your right to cancel an Order only applies to Goods that are
            returned in the same condition as You received them. You should also
            include all of the products instructions, documents and wrappings.
            Goods that are damaged or not in the same condition as You received
            them or which are worn simply beyond opening the original packaging
            will not be refunded. You should therefore take reasonable care of
            the purchased Goods while they are in Your possession.
          </p>
          <p class="text-sm">
            We will reimburse You no later than 14 days from the day on which We
            receive the returned Goods. We will use the same means of payment as
            You used for the Order, and You will not incur any fees for such
            reimbursement.
          </p>
          <p class="text-sm">
            You will not have any right to cancel an Order for the supply of any
            of the following Goods:
          </p>
          <ul style="list-style: disc" class="text-sm ml-8">
            <li>
              The supply of Goods made to Your specifications or clearly
              personalized.
            </li>
            <li>
              The supply of Goods which according to their nature are not
              suitable to be returned, deteriorate rapidly or where the date of
              expiry is over.
            </li>
            <li>
              The supply of Goods which are not suitable for return due to
              health protection or hygiene reasons and were unsealed after
              delivery.
            </li>
            <li>
              The supply of Goods which are, after delivery, according to their
              nature, inseparably mixed with other items.
            </li>
            <li>
              The supply of digital content which is not supplied on a tangible
              medium if the performance has begun with Your prior express
              consent and You have acknowledged Your loss of cancellation right.
            </li>
          </ul>
          <p class="text-sm">
            We reserve the right to refuse or cancel Your Order if fraud or an
            unauthorized or illegal transaction is suspected.
          </p>
        </div>

        <p class="text-xl md:text-xl font-normal  my-2">
          Availability, Errors and Inaccuracies
        </p>
        <div class="font-sans">
          <p class="text-sm">
            We are constantly updating Our offerings of Goods on the Service.
            The Goods available on Our Service may be mispriced, described
            inaccurately, or unavailable, and We may experience delays in
            updating information regarding our Goods on the Service and in Our
            advertising on other websites.
          </p>
          <p class="text-sm">
            We cannot and do not guarantee the accuracy or completeness of any
            information, including prices, product images, specifications,
            availability, and services. We reserve the right to change or update
            information and to correct errors, inaccuracies, or omissions at any
            time without prior notice.
          </p>
        </div>

        <p class="text-xl md:text-xl font-normal  my-2">Prices Policy</p>
        <div class="font-sans">
          <p class="text-sm">
            The Company reserves the right to revise its prices at any time
            prior to accepting an Order.
          </p>
          <p class="text-sm">
            The prices quoted may be revised by the Company subsequent to
            accepting an Order in the event of any occurrence affecting delivery
            caused by government action, variation in customs duties, increased
            shipping charges, higher foreign exchange costs and any other matter
            beyond the control of the Company. In that event, You will have the
            right to cancel Your Order.
          </p>
        </div>

        <p class="text-xl md:text-xl font-normal  my-2">Payments</p>
        <div class="font-sans">
          <p class="text-sm">
            All Goods purchased are subject to a one-time payment. Payment can
            be made through various payment methods we have available, such as
            Visa, MasterCard, Affinity Card, American Express cards or online
            payment methods (PayPal, for example).
          </p>
          <p class="text-sm">
            Payment cards (credit cards or debit cards) are subject to
            validation checks and authorization by Your card issuer. If we do
            not receive the required authorization, We will not be liable for
            any delay or non-delivery of Your Order.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">Subscriptions</p>

        <p class="text-xl md:text-xl font-normal  my-2">Subscription period</p>
        <div class="font-sans">
          <p class="text-sm">
            The Service or some parts of the Service are available only with a
            paid Subscription. You will be billed in advance on a recurring and
            periodic basis (such as daily, weekly, monthly or annually),
            depending on the type of Subscription plan you select when
            purchasing the Subscription.
          </p>
          <p class="text-sm">
            At the end of each period, Your Subscription will automatically
            renew under the exact same conditions unless You cancel it or the
            Company cancels it.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">
          Subscription cancellations
        </p>
        <div class="font-sans">
          <p class="text-sm">
            You may cancel Your Subscription renewal either through Your Account
            settings page or by contacting the Company. You will not receive a
            refund for the fees You already paid for Your current Subscription
            period and You will be able to access the Service until the end of
            Your current Subscription period.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">Billing</p>
        <div class="font-sans">
          <p class="text-sm">
            You shall provide the Company with accurate and complete billing
            information including full name, address, state, zip code, telephone
            number, and a valid payment method information.
          </p>
          <p class="text-sm">
            Should automatic billing fail to occur for any reason, the Company
            will issue an electronic invoice indicating that you must proceed
            manually, within a certain deadline date, with the full payment
            corresponding to the billing period as indicated on the invoice.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">Fee Changes</p>
        <div class="font-sans">
          <p class="text-sm">
            The Company, in its sole discretion and at any time, may modify the
            Subscription fees. Any Subscription fee change will become effective
            at the end of the then-current Subscription period.
          </p>
          <p class="text-sm">
            The Company will provide You with reasonable prior notice of any
            change in Subscription fees to give You an opportunity to terminate
            Your Subscription before such change becomes effective.
          </p>
          <p class="text-sm">
            Your continued use of the Service after the Subscription fee change
            comes into effect constitutes Your agreement to pay the modified
            Subscription fee amount.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">Refunds</p>
        <div class="font-sans">
          <p class="text-sm">
            Except when required by law, paid Subscription fees are
            non-refundable.
          </p>
          <p class="text-sm">
            Certain refund requests for Subscriptions may be considered by the
            Company on a case-by-case basis and granted at the sole discretion
            of the Company.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">Free Trial</p>
        <div class="font-sans">
          <p class="text-sm">
            The Company may, at its sole discretion, offer a Subscription with a
            Free Trial for a limited period of time.
          </p>
          <p class="text-sm">
            You may be required to enter Your billing information in order to
            sign up for the Free Trial.
          </p>
          <p class="text-sm">
            If You do enter Your billing information when signing up for a Free
            Trial, You will not be charged by the Company until the Free Trial
            has expired. On the last day of the Free Trial period, unless You
            cancelled Your Subscription, You will be automatically charged the
            applicable Subscription fees for the type of Subscription You have
            selected.
          </p>
          <p class="text-sm">
            At any time and without notice, the Company reserves the right to
            (i) modify the terms and conditions of the Free Trial offer, or (ii)
            cancel such Free Trial offer.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">Promotions</p>
        <div class="font-sans">
          <p class="text-sm">
            Any Promotions made available through the Service may be governed by
            rules that are separate from these Terms.
          </p>
          <p class="text-sm">
            If You participate in any Promotions, please review the applicable
            rules as well as our Privacy policy. If the rules for a Promotion
            conflict with these Terms, the Promotion rules will apply.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">User Accounts</p>
        <div class="font-sans">
          <p class="text-sm">
            When You create an account with Us, You must provide Us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of Your account on Our Service.
          </p>
          <p class="text-sm">
            You are responsible for safeguarding the password that You use to
            access the Service and for any activities or actions under Your
            password, whether Your password is with Our Service or a Third-Party
            Social Media Service.
          </p>
          <p class="text-sm">
            You agree not to disclose Your password to any third party. You must
            notify Us immediately upon becoming aware of any breach of security
            or unauthorized use of Your account.
          </p>
          <p class="text-sm">
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than You
            without appropriate authorization, or a name that is otherwise
            offensive, vulgar or obscene.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Intellectual Property
        </p>
        <div class="font-sans">
          <p class="text-sm">
            The Service and its original content (excluding Content provided by
            You or other users), features and functionality are and will remain
            the exclusive property of the Company and its licensors.
          </p>
          <p class="text-sm">
            The Service is protected by copyright, trademark, and other laws of
            both the Country and foreign countries.
          </p>
          <p class="text-sm">
            Our trademarks and trade dress may not be used in connection with
            any product or service without the prior written consent of the
            Company.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Links to Other Websites
        </p>
        <div class="font-sans">
          <p class="text-sm">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company.
          </p>
          <p class="text-sm">
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that the
            Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods or
            services available on or through any such web sites or services.
          </p>
          <p class="text-sm">
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">Termination</p>
        <div class="font-sans">
          <p class="text-sm">
            We may terminate or suspend Your Account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
          </p>
          <p class="text-sm">
            Upon termination, Your right to use the Service will cease
            immediately. If You wish to terminate Your Account, You may simply
            discontinue using the Service.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Limitation of Liability
        </p>
        <div class="font-sans">
          <p class="text-sm">
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven't purchased anything
            through the Service.
          </p>
          <p class="text-sm">
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>
          <p class="text-sm">
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. In
            these states, each party's liability will be limited to the greatest
            extent permitted by law.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          "AS IS" and "AS AVAILABLE" Disclaimer
        </p>
        <div class="font-sans">
          <p class="text-sm">
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, the Company, on its own
            behalf and on behalf of its Affiliates and its and their respective
            licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to
            the Service, including all implied warranties of merchantability,
            fitness for a particular purpose, title and non-infringement, and
            warranties that may arise out of course of dealing, course of
            performance, usage or trade practice. Without limitation to the
            foregoing, the Company provides no warranty or undertaking, and
            makes no representation of any kind that the Service will meet Your
            requirements, achieve any intended results, be compatible or work
            with any other software, applications, systems or services, operate
            without interruption, meet any performance or reliability standards
            or be error free or that any errors or defects can or will be
            corrected.
          </p>
          <p class="text-sm">
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied: (i) as to the operation or availability of the
            Service, or the information, content, and materials or products
            included thereon; (ii) that the Service will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the Service; or (iv)
            that the Service, its servers, the content, or e-mails sent from or
            on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components.
          </p>
          <p class="text-sm">
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">Governing Law</p>
        <div class="font-sans">
          <p class="text-sm">
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Disputes Resolution
        </p>
        <div class="font-sans">
          <p class="text-sm">
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          For European Union (EU) Users
        </p>
        <div class="font-sans">
          <p class="text-sm">
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          United States Federal Government End Use Provisions
        </p>
        <div class="font-sans">
          <p class="text-sm">
            If You are a U.S. federal government end user, our Service is a
            "Commercial Item" as that term is defined at 48 C.F.R. §2.101.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          United States Legal Compliance
        </p>
        <div class="font-sans">
          <p class="text-sm">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Severability and Waiver
        </p>
        <p class="text-xl md:text-xl font-normal  my-2">Severability</p>
        <div class="font-sans">
          <p class="text-sm">
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
        </div>
        <p class="text-xl md:text-xl font-normal  my-2">Waiver</p>
        <div class="font-sans">
          <p class="text-sm">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Translation Interpretation
        </p>
        <div class="font-sans">
          <p class="text-sm">
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">
          Changes to These Terms and Conditions
        </p>
        <div class="font-sans">
          <p class="text-sm">
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at Our sole discretion.
          </p>
          <p class="text-sm">
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the website and the Service.
          </p>
        </div>

        <p class="text-3xl md:text-3xl font-normal  mt-8 mb-4">Contact Us</p>
        <div class="font-sans">
          <p class="text-sm">
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <ul style="list-style: disc" class="text-sm ml-8">
            <li>By email: support@spaceseats.io</li>
          </ul>
        </div>
      </div>
    </div>
    <!--/container-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  components: {
    Header
  },
  mounted() {
    // Add background image in body tag
    let body = document.getElementsByTagName("body")[0];
    body.style.background = `url(${require("@/assets/img/landing/banner.png")}) repeat`;
    body.style.color = "#fff";
  }
};
</script>
